<template>
   <div class="ListBoxShow"
    :style="isShowInfo?'display:flex':''">
        <div class="w_10">
            <i class="p-dialog-header-close-icon pi pi-times" @click.stop="close"></i>
             <!-- <div>
                <p>武器总星数:
                    <span class="ml-2 text-warning">{{roleInfo.role.armsTotalStar}}
                    </span>
                    </p>
                <p>体力:  <span  class="ml-2 text-warning">{{roleInfo.role.ps}}</span></p>
                <p>战力:  <span  class="ml-2 text-warning">{{roleInfo.minePower.toNumber()}}</span></p>
            </div> -->
           
            <div class="listBtn mb-5">
                <Button label="武器" icon="iconfont icon-jian-tianchong" 
                class="mr-1" @click.stop="getRoleInfo(0)"
                :class="roleInfoType?'p-button-raised p-button-secondary':''"></Button>
                <Button label="宝石" icon="iconfont icon-zhubaopeishi"
                class="ml-1" :class="roleInfoType?'':'p-button-raised p-button-secondary'"
                @click.stop="getRoleInfo(1)"></Button>
            </div>
            <div class="listBoxH"  v-if="type=='market'">
               
                <div class="listBox" v-for="item,index in roleInfo[1]" v-if="roleInfoType==0"
                :style="{backgroundImage:'url('+require('@/assets/img/listbg/arm'+item.number.toNumber()+'.png')+')'}">
                    <p>{{item.name}}</p>
                    <p>品质：{{item.quality.toNumber()}}</p>
                </div>
                <div class="listBox gemList" v-for="item,index in roleInfo[0]" v-if="roleInfoType==1"
                :style="{backgroundImage:'url('+require('@/assets/img/listbg/gem'+item.quality.toNumber()+'.png')+')'}" >
                    <p>{{item.name}}</p>
                    <p>品质：{{item.quality.toNumber()}}</p>
                </div>
            </div>
            <div class="listBoxH" v-else>
               
             
                <div class="listBox" v-for="item,index in armsList" v-if="roleInfoType==0"
                :style="{backgroundImage:'url('+require('@/assets/img/listbg/arm'+item.number+'.png')+')'}">
                    <p>{{item.name}}</p>
                    
                    <p>品质：{{item.quality}}</p>
                </div>
                <div class="listBox gemList" v-for="item,index in gemList" v-if="roleInfoType==1"
                :style="{backgroundImage:'url('+require('@/assets/img/listbg/gem'+item.quality.toNumber()+'.png')+')'}" >
                    <p>{{item.name}}</p>
                    <div>
                    <p class="mb-0">品质：{{item.quality.toNumber()}}</p>
                    <p>伤害：{{item.aggressivity.toNumber()}}</p>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</template>

<script>
import { defineComponent,ref,watch } from 'vue';
import Dialog from 'primevue/dialog'; 
import InputText from 'primevue/inputtext';
import {sortArr} from "../../hooks/getAttr";
export default defineComponent({
    name:"RoleDetailBox",
    emits: ["createRoleEvent"],
    props: [
        'roleInfo',
        'itemIndex',
        'type'
    ],
    setup(props,{emit}) {
       //获取当前角色信息
		let isShowInfo=ref(false);
		let roleInfoType=ref(0);
        
        //武器详情列表
        let armsList=ref(props.roleInfo.arms);
       
        armsList.value=sortArr(armsList.value,0).then(res=>{
            armsList.value = res;
           
        });

        //宝石详情
        let gemList=ref(props.roleInfo.gemstones);
       
        gemList.value=sortArr(gemList.value,0).then(res=>{
            gemList.value = res;
           
        })

       const getRoleInfo = (index) => {
		
			roleInfoType.value=index;
			
		}
        const close=()=>{
            
            emit('closeRoleDetail',props.itemIndex);
           
            return 
        }
        return {
            roleInfoType,
            getRoleInfo,
            close,
          armsList,
          gemList
        }
        
    },
    components : {
        Dialog,
        
        InputText
    }
    
})
</script>
<style lang="scss" scoped>
.ListBoxShow{
		padding: 10px;
		border-top:5px solid $themeColor;
		position: absolute;
		width:100%;
		height: 100%;
		background: rgba(0,0,0,0.7);
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
        overflow: auto;
        padding-top: 30px;
		i{
			text-align: right;
			display: block;
			width: 100%;
			margin-right: 20px;
			margin-bottom: 20px;
		}
        .listBoxH{
            height: 300px;
            overflow: auto;
        }
		.listBtn{
			width: 100%;
			display: flex;
			.p-button{
				flex:1;
				border-radius: $radisIconbtn;
			}
		}
		.listBox{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 15px 10px;
			margin-bottom: 10px;
			border-radius: $radisIconbtn;
			
			object-fit: cover;
			background-size: cover;
		
             background-position: center;
			margin: 10px 0;
		
			p{
				margin: 0;
			}
		}
	}
</style>